import React from "react";
import styles from "./FooterLogo.module.css";

const FooterLogo = () => {
    return (
        <svg viewBox="0 0 1285.67 816.3">
            <text
                className={styles.cls1}
                transform="translate(462.34 208.66) scale(0.93 1)"
            >
                PURPLE
                <tspan x="0" y="214.66">
                    DOOR
                </tspan>
                <tspan className={styles.cls2}>
                    <tspan x="0" y="429.33">
                        FINDERS
                    </tspan>
                </tspan>
            </text>
            <rect className={styles.cls3} x="4.7" y="36.67" width="78.83" height="133.82" />
            <rect
                className={styles.cls3}
                x="107.86"
                y="36.67"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="213.01"
                y="36.67"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="316.17"
                y="36.67"
                width="78.83"
                height="133.82"
            />
            <rect className={styles.cls3} x="4.7" y="191" width="78.83" height="133.82" />
            <rect
                className={styles.cls4}
                x="107.86"
                y="191"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="213.01"
                y="191"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="316.17"
                y="191"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="4.7"
                y="346.55"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="107.86"
                y="346.55"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="213.01"
                y="346.55"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="316.17"
                y="346.55"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="4.7"
                y="503.25"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="107.86"
                y="503.25"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="213.01"
                y="503.25"
                width="78.83"
                height="133.82"
            />
            <rect
                className={styles.cls3}
                x="316.17"
                y="503.25"
                width="78.83"
                height="133.82"
            />
            <text className={styles.cls5} transform="translate(0 754.89) scale(0.93 1)">
                DISCOVER YOUR RIGHT FIT
                <tspan className={styles.cls6} x="1319.45" y="-43.43">
                    TM
                </tspan>
            </text>
        </svg>
    );
};

export default FooterLogo;
