export const isSignedIn = (auth) => {
    const user = auth.user;

    if (user) {
        if (user.provider === "password") {
            return user.emailVerified;
        } else {
            return true;
        }
    } else {
        return false;
    }
};

// track user button click count in local storage, per button name
export const trackButtonClick = (buttonName) => {
    let count = localStorage.getItem(buttonName);
    count = count ? parseInt(count) + 1 : 1;
    localStorage.setItem(buttonName, count);
    return count;
};
