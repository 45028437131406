import React, { useContext } from "react";
import Link from "@/utils/link";

export const OutlineButton = ({ children, dark = true, className }) => {
    let c = className + " px-2 py-1 border rounded font-bold ";
    if (dark) {
        c +=
            "border-primary-900 text-primaty-900 hover:border-primary-400 hover:text-primary-100 hover:bg-primary-400";
    } else {
        c +=
            "border-primary-100 text-primaty-100 hover:border-primary-400 hover:bg-primary-400 hover:bg-opacity-80";
    }

    return <button className={c}>{children}</button>;
};

const IconButtonGroupContext = React.createContext();

export const IconButtonGroup = ({ children }) => {
    return (
        <div className="border border-gray-400 flex items-center rounded-xl overflow-hidden">
            <IconButtonGroupContext.Provider value={{ group: true }}>
                {children}
            </IconButtonGroupContext.Provider>
        </div>
    );
};

export const IconButton = ({ children, onClick, selected, className }) => {
    const c = useContext(IconButtonGroupContext);
    const group = c?.group;

    return (
        <button
            className={`${selected ? "bg-gray-300" : ""} hover:bg-gray-300 ${
                group ? "" : "hover:rounded-full"
            } p-2 ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export const LinkButton = ({ href = "", children, className = "", dark = true }) => {
    return (
        <Link href={href}>
            <a
                className={
                    "px-8 py-2 text-lg font-bold tracking-wide rounded shadow" +
                    (dark
                        ? " text-primary-50 bg-primary-500 hover:bg-primary-med"
                        : " text-primary-500 bg-primary-50 hover:bg-primary-400") +
                    " " +
                    className
                }
            >
                {children}
            </a>
        </Link>
    );
};

export const LinkText = ({ href = "", children, className = "" }) => {
    return (
        <Link href={href}>
            <a
                className={
                    "px-8 py-2 text-lg font-bold tracking-wide bg-gray-light shadow rounded text-primary-500 hover:text-gray-50 hover:bg-gray-med " +
                    className
                }
            >
                {children}
            </a>
        </Link>
    );
};
